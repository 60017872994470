// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Home__section__3d-_n {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 100vh;\n  position: relative;\n  font-family: \"Cabin\", sans-serif;\n  color: #ffeded;\n  text-transform: uppercase;\n  font-size: 7vmin;\n  padding-left: 10%;\n  padding-right: 10%;\n}\n\nsection:nth-child(odd) {\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n", ""]);
// Exports
exports.locals = {
	"section": "Home__section__3d-_n"
};
module.exports = exports;
